/*==  Tiny Screen  ==*/
@media only screen and (min-width: 0) {
    .done-card {
        color: #f17f3e;
        border: 3px solid #f17f3e;
        margin-bottom: 0.625rem;
        padding: 0.875rem 0.75rem;
        transition: all .2s ease;
    }
    .done-card:hover {
        cursor: pointer;
        color: #252525;
        background-color: #f17f3e;
    }
   .done-card:hover .done-card__header-wrapper .done-card__icon {
        border-left: 0.5625rem solid #252525;
   }

    .done-card__header-wrapper {
        color: inherit;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .done-card__header {
        color: inherit;
        font-size: 1.375rem;
        transition: all .05s ease;
    }

    .done-card__header-link {
        color: inherit;
        text-decoration: underline;
    }

    .done-card__icon {
        display: block;
        width: 0;
        height: 0;
        margin-right: 0.375rem;
        border-top: 0.5625rem solid transparent;
        border-bottom: 0.5625rem solid transparent;
        border-left: 0.5625rem solid #f17f3e;
        transition: .2s ease rotate;
    }

    .done-card--open .done-card__icon {
        rotate: 90deg;
    }

    .done-card__description {
        max-height: 0;
        color: inherit;
        font-size: 0.875rem;
        text-decoration: none;
        overflow: hidden;
        transition: max-height .3s ease;
    }

    .done-card--open .done-card__description {
        display: block;
        max-height: 6.25rem;
        margin-top: 0.625rem;
    }
}

/*== Landscape Tablets / Small Desktop Browser Window ==*/
@media only screen and (min-width: 768px) {
    .done-card__description {
        font-size: 1rem;
    }
}