/*==  Tiny Screen  ==*/
@media only screen and (min-width: 0) {
    .form-contact {
        max-width: 26rem;
        margin: 0 auto; 
    }
    .form-field {
        margin-bottom: 1rem;
    }
    .form-field:last-of-type {
        margin-bottom: 0;
    }

    .form-label {
        display: block;
        font-size: 1rem;
        font-weight: 600;
        padding: .2rem;
    }

    .form-input {
        font-size: 1rem;
        font-weight: 600;
        width: 100%;
        min-height: 1rem;
        padding: .6rem .8rem;
        background-color: transparent;
        border: none;
        border-bottom: .2rem solid #f17f3e;
        transition: .1s all ease;
    }
    .form-input:focus,
    .form-input:focus-visible {
        border: none;
        border-bottom: .2rem solid #f17f3e;
        outline: none;
    }
    .form-input--large-font {
        font-size: 1.4rem;
    }

    .form-textarea {
        font-size: 1.2rem;
        width: 100%;
        min-width: 100%;
        max-width: 100%;
        min-height: 5rem;
        max-height: 10rem;
        padding: 0.625rem;
        background-color: transparent;
        border: none;
        border-bottom: .2rem solid #f17f3e;
        border-radius: 2px;
        transition: all .1s ease;
    }
    .form-textarea:focus,
    .form-textarea:focus-visible {
        border: none;
        border-bottom: .2rem solid #f17f3e;
        outline: none;
    }

    .form-button {
        text-align: left;
        width: 100%;
        margin-top: 0.625rem;
        height: 3.75rem;
    }
}