/*==  Tiny Screen ==*/
@media only screen and (min-width: 0) {
    /*
    This is the secret sauce for the page to fit the screen on desktop:
        height: auto;
        min-height: 100vh;
    */
    
    .section {
        width: 100%;
        height: 100%;
        min-height: 100vh;
        padding: 1.125rem 0;
        /* The magic that keeps the borders fitting the screen edges on section scrolls. */
        margin-bottom: -0.4375rem;
        scroll-snap-align: start;
    }
    .section--flex-centered {
        display: flex;
            align-items: center;
    }
    .section--bordered {
        background-color: #252525;
        border: 0.4375rem solid #f17f3e;
        border-bottom: 0;
    }
    .section--bordered-top {
        background-color: #252525;
        border-top: 0.4375rem solid #f17f3e;
    }
    .section--bordered-top:last-of-type,
    .section--bordered:last-of-type {
        border-bottom: 0.4375rem solid #f17f3e;
    }
}